import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Table,
} from "reactstrap";
import CustomCheckbox from "../../CustomCheckbox";
import { utils } from "../../../utils/utils";
import { customersApi } from "../../../services/customerServices";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

const MergeLocationsModal = ({ customerLocations, onSubmit, onClose }) => {
  const [primaryLocation, setPrimaryLocation] = useState();
  const [loading, setLoading] = useState();

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async () => {
    if (!primaryLocation) {
      return setInformationModal({
        isOpen: true,
        title: "Merge Customer Locations",
        body: "Please select a primary location.",
      });
    }
    try {
      const idsToMerge = customerLocations
        .map((cl) => cl.id)
        .filter((cl) => cl !== primaryLocation);
      setLoading(true);
      await customersApi.mergeLocations({
        primary: primaryLocation,
        idsToMerge,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Merge Customer Locations",
        body: "Locations merged successfully",
        onClose: onSubmit,
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Merge Customer Locations",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };
  // Related: DES-351, DS-799, DS-1108
  const primaryCustomerLocations = customerLocations; /*.filter(
    (location) => location.shipToCode
  );*/

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              rawBody: false,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Merge Customer Locations</ModalHeader>
      <ModalBody>
        <ListGroup>
          <ListGroupItem
            className="justify-content-center bg-lighter d-flex align-items-center font-weight-bold"
            tag="div"
          >
            Select the primary location
          </ListGroupItem>
          {loading ? (
            <Loader size="sm" className="mt-4" />
          ) : (
            <Table className="col-12 px-0 border rounded mb-0">
              <thead className="small text-muted">
                <tr className="bg-lighter">
                  <th className="text-left">Location</th>
                  <th className="text-right">Primary</th>
                </tr>
              </thead>
              <tbody>
                {primaryCustomerLocations.length ? (
                  primaryCustomerLocations.map((location) => {
                    return (
                      <tr
                        key={location.id}
                        onClick={() => setPrimaryLocation(location.id)}
                        className="cursor-pointer"
                      >
                        <td className="text-left">
                          <span>{utils.getAddress(location)}</span>
                          {location.shipToCode ? (
                            <span className="text-muted ml-1">
                              ({location.shipToCode})
                            </span>
                          ) : null}
                        </td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <CustomCheckbox
                              checked={primaryLocation === location.id}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <td colSpan={2}>
                    There are no locations with Ship to Code set to use as
                    primary
                  </td>
                )}
              </tbody>
            </Table>
          )}
        </ListGroup>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
        <Button color={"primary"} onClick={doSubmit}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MergeLocationsModal;
