import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";

import {
  Button,
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
  Input,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";

import CustomerLocationContactsModal from "../../components/admin/customer/CustomerLocationContactsModal";
import CustomerLocationCranesModal from "../../components/admin/customer/CustomerLocationCranesModal";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import InformationModal from "../../components/InformationModal";
import Loader from "../../components/Loader";
import { useAuth } from "../../providers/authProvider";

import { customersApi } from "../../services/customerServices";
import { utils } from "../../utils/utils";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomCheckbox from "../../components/CustomCheckbox";
import MergeLocationsModal from "../../components/admin/customer/MergeLocationsModal";

const EMPLOYEE_ROLE_MANAGER = 2;

const CustomerDetails = () => {
  const { customerId } = useParams();
  const [authContext] = useAuth();
  const navigate = useNavigate();

  const [customerData, setCustomerData] = useState();
  const [customerLocationCranesModal, setCustomerLocationCranesModal] =
    useState();
  const [customerLocationContactsModal, setCustomerLocationContactsModal] =
    useState();
  const [workOrders, setWorkOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingShowLocationName, setLoadingShowLocationName] = useState(false);
  const [refresh, setRefresh] = useState();

  const [locationSearch, setLocationSearch] = useState();
  const [selectedLocations, setSelectedLocations] = useState({});
  const [mergeLocationsModal, setMergeLocationsModal] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const customer = await customersApi.getCustomerById(customerId);
        setCustomerData(customer);
        const workOrders = customer.workOrders.filter((wo) =>
          authContext.currentUser.roleId === EMPLOYEE_ROLE_MANAGER
            ? wo.serviceLocationId === authContext.serviceLocationId
            : true
        );
        setWorkOrders(workOrders);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, [
    customerId,
    authContext.currentUser.roleId,
    authContext.serviceLocationId,
    refresh,
  ]);

  const onSeeCranes = (element) => {
    if (!element.cranes?.length) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There are no cranes to show.",
      });
    }
    setCustomerLocationCranesModal(element);
  };

  const goBack = () => {
    try {
      navigate(-1);
    } catch (err) {
      navigate("/customers");
    }
  };

  const filteredLocations = customerData?.locations.filter((cl) =>
    locationSearch
      ? utils
          .getAddress(cl)
          .toLowerCase()
          .indexOf(locationSearch.toLowerCase()) > -1
      : true
  );

  const onSelectLocation = (location) => {
    setSelectedLocations({
      ...selectedLocations,
      [location.id]: selectedLocations[location.id] ? false : location,
    });
  };

  const onMergeLocations = () => {
    const mergeLocations = Object.values(selectedLocations).filter(Boolean);
    /* DES-351, DS-799, DS-1108
    if (!mergeLocations.find((location) => location.shipToCode)) {
      return setInformationModal({
        isOpen: true,
        title: "Merge Customer Location",
        body: "You must select at least one location with ship to code set to be set as primary.",
      });
    }*/
    setMergeLocationsModal(mergeLocations);
  };

  const onEditShowLocationName = async (data) => {
    setLoadingShowLocationName(true);
    try {
      await customersApi.updateCustomerShowLocationName({
        id: data.id,
        showLocationName: data.showLocationName,
      });
      setLoadingShowLocationName(false);
      return setInformationModal({
        isOpen: true,
        title: "Customer Details",
        body: `${
          data.showLocationName
            ? "Now you can see the location name in reports"
            : "You have disabled the option to see the location name in reports"
        }`,
      });
    } catch (err) {
      setLoadingShowLocationName(false);
      return setInformationModal({
        isOpen: true,
        title: "Customer Details",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  return (
    <Container fluid>
      <Header className="mb-3 ">
        <HeaderTitle className="d-flex flex-column align-items-start">
          <span className="col-12 px-0 col-lg-6 text-dark">
            Customer: {customerData?.customerName}
          </span>
          <Button size="sm" className="rounded mt-1" onClick={goBack}>
            <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
            Back
          </Button>
        </HeaderTitle>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardBody className="d-flex flex-column pt-4 pb-4">
              {loading ? (
                <Loader />
              ) : customerData ? (
                <div className="flex flex-column">
                  <div className="d-flex flex-wrap">
                    <div className="col-12 col-lg-6">
                      <ListGroup
                        flush
                        className="text-left border rounded col-12 px-0"
                      >
                        <ListGroupItem
                          className="border-bottom font-weight-bold text-body list-group-item bg-light text-center"
                          tag="div"
                          key="0"
                        >
                          Basic Info
                        </ListGroupItem>
                        <ListGroupItem
                          className="border-bottom d-flex justify-content-between align-items-center"
                          tag="div"
                        >
                          <span className="text-underline">Customer Name</span>
                          <span>{customerData.customerName}</span>
                        </ListGroupItem>
                        <ListGroupItem
                          className="border-bottom d-flex justify-content-between align-items-center"
                          tag="div"
                        >
                          <span className="text-underline">Customer No.</span>
                          <span>{customerData.ContactCode}</span>
                        </ListGroupItem>
                        <ListGroupItem
                          className="border-bottom d-flex justify-content-between align-items-center"
                          tag="div"
                        >
                          <span className="text-underline">Customer Email</span>
                          <span>{customerData.emailAddress}</span>
                        </ListGroupItem>
                      </ListGroup>
                      <FormGroup
                        check
                        className="d-flex align-items-center mt-3 ml-1"
                      >
                        <Input
                          id="showLocationNameCheck"
                          name="check"
                          type="checkbox"
                          style={{
                            width: "18px",
                            height: "18px",
                            cursor: "pointer",
                          }}
                          checked={customerData.showLocationName}
                          onChange={() => {
                            const updatedData = {
                              ...customerData,
                              showLocationName: !customerData.showLocationName,
                            };
                            setCustomerData(updatedData);
                            onEditShowLocationName(updatedData);
                          }}
                        />
                        <Label
                          check
                          for="showLocationNameCheck"
                          style={{
                            marginTop: "4.5px",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        >
                          Show location name in reports
                        </Label>
                        {loadingShowLocationName && (
                          <Spinner className="ml-3" size="sm" color="primary" />
                        )}
                      </FormGroup>
                    </div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                      <ListGroup
                        flush
                        className="text-left border rounded col-12 px-0"
                      >
                        <ListGroupItem
                          className="border-bottom font-weight-bold text-body list-group-item bg-light text-center"
                          tag="div"
                          key="0"
                        >
                          Contact Info
                        </ListGroupItem>
                        <ListGroupItem
                          className="border-bottom d-flex justify-content-between align-items-center"
                          tag="div"
                        >
                          <span className="text-underline">Address</span>
                          <span>{`${customerData.addressLine1}, ${customerData.zipCode}`}</span>
                        </ListGroupItem>
                        <ListGroupItem
                          className="border-bottom d-flex justify-content-between align-items-center"
                          tag="div"
                        >
                          <span className="text-underline">City</span>
                          <span>{customerData.city}</span>
                        </ListGroupItem>
                        <ListGroupItem
                          className="border-bottom d-flex justify-content-between align-items-center"
                          tag="div"
                        >
                          <span className="text-underline">Country</span>
                          <span>{customerData.countryCode}</span>
                        </ListGroupItem>
                        <ListGroupItem
                          className="border-bottom d-flex justify-content-between align-items-center"
                          tag="div"
                        >
                          <span className="text-underline">Telephone</span>
                          <span>{customerData.telephoneNo}</span>
                        </ListGroupItem>
                      </ListGroup>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap  mt-4">
                    <div className="col-12 col-lg-6">
                      <ListGroup
                        flush
                        className="text-left border rounded col-12 px-0"
                      >
                        <ListGroupItem
                          className="border-bottom font-weight-bold text-body list-group-item bg-light text-center"
                          tag="div"
                          key="0"
                        >
                          Customer Locations
                        </ListGroupItem>
                        {!customerData.locations.length ? (
                          <ListGroupItem
                            className="border-bottom d-flex justify-content-between align-items-center"
                            tag="div"
                            key="1"
                          >
                            No locations
                          </ListGroupItem>
                        ) : (
                          <>
                            <ListGroupItem
                              className="border-bottom d-flex justify-content-between align-items-center"
                              tag="div"
                            >
                              {Object.values(selectedLocations).filter(Boolean)
                                .length > 1 ? (
                                <Button
                                  size="sm"
                                  className="rounded mr-2"
                                  onClick={onMergeLocations}
                                >
                                  Merge
                                </Button>
                              ) : null}
                              <Input
                                maxLength="255"
                                type="text"
                                placeholder="Search a location.."
                                value={locationSearch}
                                onChange={(e) =>
                                  setLocationSearch(e.target.value)
                                }
                              />
                            </ListGroupItem>
                            {filteredLocations.map((element, index) => (
                              <ListGroupItem
                                className="border-bottom d-flex justify-content-between align-items-center cursor-pointer"
                                tag="div"
                                key={index + 1}
                                onClick={() => onSelectLocation(element)}
                              >
                                <div className="d-flex justify-content-start align-items-center col-7">
                                  <CustomCheckbox
                                    checked={selectedLocations[element.id]}
                                  />
                                  <span className="ml-4 mr-2">
                                    {utils.getAddress(element)}
                                  </span>
                                  {element.shipToCode ? (
                                    <span className="text-muted ml-1">
                                      ({element.shipToCode})
                                    </span>
                                  ) : null}
                                </div>
                                <div className="d-flex justify-content-end align-items-center col-5">
                                  {element.shipToCode ? (
                                    <Badge
                                      className="small mr-2"
                                      color="success"
                                    >
                                      Primary
                                    </Badge>
                                  ) : null}
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="rounded"
                                    onClick={(evt) => {
                                      evt.stopPropagation();
                                      setCustomerLocationContactsModal(element);
                                    }}
                                  >
                                    See Contacts
                                  </Button>
                                  <Button
                                    size="sm"
                                    className="rounded ml-2"
                                    onClick={(evt) => {
                                      evt.stopPropagation();
                                      onSeeCranes(element);
                                    }}
                                  >
                                    See Cranes
                                  </Button>
                                </div>
                              </ListGroupItem>
                            ))}
                          </>
                        )}
                      </ListGroup>
                    </div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                      <ListGroup
                        flush
                        className="text-left border rounded col-12 px-0"
                      >
                        <ListGroupItem
                          className="border-bottom font-weight-bold text-body list-group-item bg-light text-center"
                          tag="div"
                          key="0"
                        >
                          Work Orders
                        </ListGroupItem>
                        {!workOrders.length ? (
                          <ListGroupItem
                            className="border-bottom d-flex justify-content-between align-items-center"
                            tag="div"
                            key="1"
                          >
                            No work orders
                          </ListGroupItem>
                        ) : (
                          <Table className="col-12 px-0 border rounded mb-0">
                            <thead className="small text-muted">
                              <tr className="bg-lighter">
                                <th>WO #</th>
                                <th>Service Requested</th>
                                <th>Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              {workOrders.map((element) => (
                                <tr
                                  onClick={() =>
                                    navigate(
                                      `/work-orders/${element.id}/details`
                                    )
                                  }
                                  key={element.id}
                                  className="cursor-pointer"
                                >
                                  <td>
                                    {element.jobNo
                                      ? `#${element.jobNo}`
                                      : `Without WO# (${element.id})`}
                                  </td>
                                  <td>
                                    {utils.getWoServiceRequested(element)}
                                  </td>
                                  <td>
                                    <Badge
                                      color={utils.getJobTypeColor(
                                        element.jobType
                                      )}
                                    >
                                      {element.jobType}
                                    </Badge>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </ListGroup>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          rawBody={informationModal.rawBody}
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              rawBody: false,
            })
          }
        />
      ) : customerLocationCranesModal ? (
        <CustomerLocationCranesModal
          customerLocation={customerLocationCranesModal}
          onClose={() => setCustomerLocationCranesModal()}
        />
      ) : mergeLocationsModal ? (
        <MergeLocationsModal
          customerLocations={mergeLocationsModal}
          onSubmit={() => {
            setRefresh(!refresh);
            setLocationSearch();
            setSelectedLocations({});
            setMergeLocationsModal();
          }}
          onClose={() => setMergeLocationsModal()}
        />
      ) : customerLocationContactsModal ? (
        <CustomerLocationContactsModal
          customerLocation={customerLocationContactsModal}
          onClose={() => setCustomerLocationContactsModal()}
          onSubmit={() => {
            setCustomerLocationContactsModal();
            setRefresh(!refresh);
            return setInformationModal({
              isOpen: true,
              title: "Update location customer contacts",
              body: "Location customer contacts updated successfully",
            });
          }}
        />
      ) : null}
    </Container>
  );
};

export default CustomerDetails;
