import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Row } from "reactstrap";
import Header from "../../../Header";
import HeaderTitle from "../../../HeaderTitle";
import InformationModal from "../../../InformationModal";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ACTIONS,
  useCraneConfigModal,
} from "../../../../providers/craneConfigModalProvider";
import { useNavigate, useParams } from "react-router-dom";
import { cranesApi } from "../../../../services/craneServices";
import CraneConfigGeneralModal from "./CraneConfigGeneralModal";
import CraneConfigStructureModal from "./CraneConfigStructureModal";
import CraneConfigHoistsModal from "./CraneConfigHoistsModal";
import CraneConfigControlsModal from "./CraneConfigControlsModal";
import { CRANE_SERVICE_STATUS, utils } from "../../../../utils/utils";
import { awsApi } from "../../../../services/awsService";
import { v1 as uuidv1 } from "uuid";
import Loader from "../../../Loader";
const HOIST_ONLY = "HOIST_ONLY";

const Crane = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { craneId } = params;
  const isCreate = craneId === "new";
  const [craneConfigModalContext, setCraneConfigModalContext] =
    useCraneConfigModal();
  const [crane, setCrane] = useState({});
  const [craneStructure, setCraneStructure] = useState({});
  const [craneHoists, setCraneHoists] = useState([]);
  const [craneControls, setCraneControls] = useState([]);
  const [runway, setRunway] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [deletedHoists, setDeletedHoists] = useState([]);
  const craneConfigGeneralModalRef = useRef(null);
  const craneConfigStructureModalRef = useRef(null);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmitPhotos = async ({ craneIdParam }) => {
    setLoading(true);
    try {
      const upload = photos
        .filter((img) => !img.id)
        .map((item, i) => ({
          ...item,
          fileName: `crane_attachment_${craneIdParam}_${uuidv1()}_${i}`,
        }));

      const documents = upload.map((item) => ({
        ...item,
        method: "putObject",
      }));

      const signedURLs = documents.length
        ? await awsApi.signDocuments({ documents })
        : [];

      const elements = signedURLs.filter((sign) => sign.success);
      const fileUploads = elements.map(async (element) => {
        const fileToUpload = upload.find(
          (file) => file.fileName === element.fileName
        );
        const file = await utils.srcToFile(
          fileToUpload.contentUrl,
          fileToUpload.fileName,
          fileToUpload.fileType
        );
        await awsApi.putDocumentsToS3({
          url: element.signedRequest,
          file,
          type: fileToUpload.fileType,
        });
        return cranesApi.createCraneAttachments({
          craneId: craneIdParam,
          contentUrl: element.url,
        });
      });

      const deleteRequests = deletedPhotos.map((photo) =>
        cranesApi.deleteCraneAttachments(photo.id)
      );

      await Promise.all([...fileUploads, ...deleteRequests]);
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleExternalSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const onSubmitConfigGeneral =
        await craneConfigGeneralModalRef.current.submitForm();
      const onSubmitConfigStructure =
        await craneConfigStructureModalRef.current.submitForm();

      if (!onSubmitConfigGeneral || !onSubmitConfigStructure) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Altert",
          body: "Please complete all required fields",
        });
      }

      const isHoistOnly =
        !craneStructure || craneStructure.craneStructureTypeId === HOIST_ONLY;
      if (isHoistOnly && !craneHoists?.length) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Crane Hoists",
          body: "You must add at least one hoist to continue.",
        });
      }

      if (!craneControls?.length) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Crane Controls",
          body: "You must add at least one control to continue.",
        });
      }

      let createdCrane;

      if (crane.id) {
        await cranesApi.updateCrane({
          ...crane,
          withRunway: runway,
        });
        createdCrane = crane;
      } else {
        createdCrane = await cranesApi.createCrane({
          ...crane,
          withRunway: runway,
          serviceStatus: crane.serviceStatus
            ? crane.serviceStatus
            : CRANE_SERVICE_STATUS["IN SERVICE"],
        });
      }

      if (craneStructure.craneStructureTypeId === HOIST_ONLY) {
        if (craneStructure.id) {
          if (!craneHoists?.length) {
            setInformationModal({
              isOpen: true,
              title: "Error",
              body: "Crane must have at least one hoist to be hoist only. Please add the hoist and then change the structure.",
            });
            setLoading(false);
            return;
          }
          await cranesApi.deleteCraneStructure({ id: craneStructure.id });
        }
      } else {
        if (craneStructure.id) {
          await cranesApi.updateCraneStructure({
            ...craneStructure,
          });
        } else {
          await cranesApi.createCraneStructure({
            ...craneStructure,
            craneId: createdCrane.id,
          });
        }
      }

      await Promise.all([
        ...craneHoists.map(async (hoist) => {
          if (!hoist.id) {
            await cranesApi.createCraneHoist({
              ...hoist,
              withTrolley: Boolean(hoist.withTrolley),
              craneId: createdCrane.id,
            });
          } else {
            await cranesApi.updateCraneHoist({
              ...hoist,
              withTrolley: Boolean(hoist.withTrolley),
              craneId: createdCrane.id,
            });
          }
        }),

        ...deletedHoists.map(async (deletedHoist) => {
          if (crane.id) {
            await cranesApi.deleteCraneHoist({
              id: deletedHoist.id,
            });
          }
        }),

        ...craneControls.map(async (control) => {
          if (!control.id) {
            await cranesApi.createCraneControl({
              ...control,
              craneId: createdCrane.id,
            });
          }
        }),

        doSubmitPhotos({ craneIdParam: createdCrane.id }),
      ]);

      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Crane ${crane.id ? "edited" : "created"}`,
        body: `The crane has been  ${
          crane.id ? "edited" : "created"
        } successfully`,
        submit: true,
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  useEffect(() => {
    setInitialLoading(true);
    const fetchData = async () => {
      const crane = await cranesApi.getCranes({ id: craneId });
      setCrane(crane);
      setCraneConfigModalContext({
        action: ACTIONS.INIT,
        payload: { crane: crane },
      });
      setRunway(crane.withRunway);
      setInitialLoading(false);
    };
    if (!isCreate) {
      fetchData();
    } else {
      setInitialLoading(false);
    }
  }, [craneId, isCreate, setCraneConfigModalContext]);

  const goBack = () => {
    try {
      navigate(-1);
    } catch (err) {
      navigate(`/ customers / cranes`);
    }
  };

  return (
    <Container fluid>
      {informationModal.isOpen ? (
        <InformationModal
          rawBody={informationModal.rawBody}
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => {
            setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              rawBody: false,
            });
            if (informationModal?.submit) {
              goBack();
            }
          }}
        />
      ) : null}
      <Header className="mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column align-items-start">
            <HeaderTitle className="text-dark">
              {!isCreate
                ? `Edit Crane: ${
                    craneConfigModalContext.crane?.ContactCode || "Loading ..."
                  }`
                : "Add New Crane"}
            </HeaderTitle>
            <Button size="sm" className="rounded mt-1" onClick={goBack}>
              <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
              Back
            </Button>
          </div>
          {loading ? (
            <Loader size="sm" align="end" />
          ) : (
            <Button onClick={handleExternalSubmit}>Save Crane</Button>
          )}
        </div>
      </Header>
      <>
        <Row>
          <CraneConfigGeneralModal
            ref={craneConfigGeneralModalRef}
            crane={crane}
            setCrane={setCrane}
            loading={initialLoading}
            setLoading={setInitialLoading}
          />
        </Row>
        <Row>
          <CraneConfigStructureModal
            ref={craneConfigStructureModalRef}
            craneStructure={craneStructure}
            setCraneStructure={setCraneStructure}
          />
        </Row>
        <Row>
          <CraneConfigHoistsModal
            craneHoists={craneHoists}
            setCraneHoists={setCraneHoists}
            craneStructure={craneStructure}
            deletedHoists={deletedHoists}
            setDeletedHoists={setDeletedHoists}
          />
        </Row>
        <Row>
          <CraneConfigControlsModal
            craneControls={craneControls}
            setCraneControls={setCraneControls}
            runway={runway}
            setRunway={setRunway}
            photos={photos}
            setPhotos={setPhotos}
            deletedPhotos={deletedPhotos}
            setDeletedPhotos={setDeletedPhotos}
          />
        </Row>
      </>
      <div className="d-flex justify-content-center align-items-center">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Button
            style={{ width: 200, marginBottom: 30 }}
            onClick={handleExternalSubmit}
          >
            Save Crane
          </Button>
        )}
      </div>
    </Container>
  );
};
export default Crane;
